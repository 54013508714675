.extra {
  padding: 0 6px;
  color: #4caf50;
  font-size: 13px;
  font-style: italic;
}

.title {
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 800;
}