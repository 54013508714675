.container {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.content {
  padding: 20px;
  flex-grow: 1;
}

.title {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 800;
}

.getStarted {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 80px;
  z-index: 999;
}

.getStartedText {
  margin-bottom: 50px;
  padding: 10px;
  border-radius: 4px;
  color: #fff;
}
