.content {
  padding: 20px;
}

.title {
  margin-bottom: 5px;
  font-size: 24px;
  font-weight: 800;
}

.subtitle {
  color: rgba(0,0,0,0.54);
  font-size: 14px;
}

.debts {
  width: 400px;
  padding: 20px;
  margin-bottom: 60px;
  background: #f5f5f5;
  border-radius: 8px;
}

.flex {
  display: flex;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.debtRows {
  margin: 12px -6px;
}

.rowDebt {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  // grid-template-columns: 2fr 1fr 1fr;
  border-radius: 8px;
  margin: 6px 0;
  padding: 6px 6px;
  width: 100%;
  cursor: pointer;

  &:hover {
    background: #f0f0f0;
  }
}

.debtHidden {
  background: #f4f4f4;

  .debtTitle {
    color: rgba(0,0,0,0.54);
  }

  .debtTotal {
    color: rgba(0,0,0,0.54);
  }
}

.debtsTitle {
  font-size: 28px;
  font-weight: 800;
}

.debtsTotal {
  font-size: 24px;
  color: #4caf50;
  font-weight: 600;
}

.debtTitle {
  font-size: 18px;
  font-weight: 600;
}

.debtRate {
  color: rgba(0,0,0,0.54);
  font-size: 14px;
  font-weight: 400;
  // text-align: center;
}

.debtHide {
  @extend .debtRate;
  text-align: right;

  span:hover {
    color: rgba(0,0,0,0.87);
    text-decoration: underline;
  }
}

.debtTotal {
  font-size: 18px;
  color: #4caf50;
  font-weight: 400;
  text-align: right;
}

.debtDelete {
  align-self: center;
  overflow: hidden;
  margin-left: 12px;
  width: 50px;
  transition: max-width 0.15s ease-in-out, margin 0.15s ease-in-out;
}