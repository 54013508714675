@import-normalize;
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  transition: all 0.2s;
}

.debts {
  width: 400px;
  padding: 20px;
  margin-bottom: 60px;
  background: #f5f5f5;
  border-radius: 8px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.row.middle {
  align-items: center;
}

.row.flex-center {
  justify-content: center;
}

.row.space-between {
  justify-content: space-between;
}

.row-debt {
  display: grid;
  align-items: baseline;
  grid-template-columns: 2fr 1fr 1fr;
  margin: 24px 0;
  width: 100%;
}

.debts-title {
  font-size: 28px;
  font-weight: 800;
}

.debts-total {
  font-size: 24px;
  color: #4caf50;
  font-weight: 600;
}

.debt-title {
  font-size: 18px;
  font-weight: 600;
}

.debt-rate {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.debt-total {
  font-size: 18px;
  color: #4caf50;
  font-weight: 300;
  text-align: right;
}

.button {
  width: 100%;
  border: none;
  border-radius: 6px;
  padding: 15px;
  color: white;
  background-color: #4caf50;
  font-size: 16px;
  font-weight: 600px;
}

.input {
  font-size: 24px;
  width: 100px;
  text-align: right;
}

.chart {
  margin: 20px 0;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 20px;
}

.bar-chart {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 500px;
  height: 320px;
  z-index: 2;
}

.bar-chart-minimum {
  position: absolute;
  top: 0px;
  z-index: 1;
}

.bar {
  flex-shrink: 0;
  margin: 0 2px;
  width: 4px;
  background-color: #4caf50;
}

.breakdown-table {
  border-collapse: separate;
  border-spacing: 25px 15px;
}

button.active {
  background-color: #ccc;
}

.dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(0, 0, 0, 0.6);
}

.dialog-content {
  padding: 20px;
  width: 240px;
  background: white;
}

.label {
  display: block;
  margin: 20px 0;
  font-size: 16px;
}

.label input {
  width: 100%;
}

.error {
  color: red;
  font-size: 12px;
}
